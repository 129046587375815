@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  font-size: 1vw;
}
.eMed_DB_container {
    display: flex;
    flex-direction: row;
    height: 100vh;
}
.eMed_DB_leftContainer {
    width: 40%;
    background-color: teal;
    height: 100%;
}
.eMed_DB_rightContainer {
    width: 60%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 10vh;
}
.eMed_DB_leftSubContainer {
    display: flex;
    flex-direction: column;
}
.eMed_DB_ContentCenterAlign {
    display: flex;
    align-items: center;
    justify-content: center;
}
.eMed_DB_txtcontentCenter {
    width: 40%;
}
.eMed_DB_lbl_valueContainer {
    display: flex;
    flex-direction: row;
}
.eMed_DB_lbl {
    min-width: 10vw;
    max-width: 10vw;
    margin: 10px;
}
.eMed_dilboxAnimations{
    width: 5vw;
    height: 10vh;
  }
  .eMed_DB_CameraBtn {
    display: flex;
    flex-direction: row
  }
  .eMed_DB_Error_txt{
    text-align: center;
    color: red;
    font-weight: 500;
    margin-top: 1vw;
    height: 1.5vw;
  }
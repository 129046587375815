.total {
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: nowrap;
  height: 100vh;
}

.left {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  flex: 1;
  background-color: white;
}

.right {
  flex: 1;
  flex-wrap: nowrap;
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.login_input input {
  display: flex;
  border-radius: 5px;
  width: 300px;
  background-color: white;
  flex-direction: column;
}
Button {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 7%;
  background-color: rgb(4, 183, 177);
  color: white;
  border: 1px solid white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.logo_img {
  height: 10%;
  display: flex;
  margin-top: 150px;
  background-color: white;
}
.link {
  color: white;
  text-decoration: none;
}

.eMed_selectItem {
  width: 50vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5vw;
}
.eMed_settingsbtn {
  margin-top: 20px;
  width: 50vw;
  display: flex;
  justify-content: center;
}

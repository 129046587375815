@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  font-size: 1vw;
}
.eMed_DB_container {
    display: flex;
    flex-direction: row;
    height: 100vh;
}
.eMed_DB_leftContainer {
    width: 40%;
    background-color: teal;
    height: 100%;
}
.eMed_DB_rightContainer {
    width: 60%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.eMed_DB_leftSubContainer {
    display: flex;
    flex-direction: column;
}
.eMed_DB_ContentCenterAlign {
    display: flex;
    align-items: center;
    justify-content: center;
}
.eMed_DB_txtcontentCenter {
    width: 100%;
    min-width: 75%;
    display: flex;
    text-align: center;
}

#eMed_DB_ButtonContainer{
    min-width: 16vw;
}
.eMed_DB_header{
    font-size: 2.5vw;
    color: #04b7b1;
}
.eMed_DB_hdr_Clinic {
    font-size:3.5vw;
    color: #04b7b1;
}
.logoutDiv{
    display: flex;
    /* flex-direction: column; */
    justify-content: flex-end;
    margin-right: 2vw;
}
.eMed_dilboxAnimations{
    width: 5vw;
    height: 10vh;
  }
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.eMed_EP_container {
    display: flex;
    flex-direction: row;
    height: 100vh;
}

.eMed_EP_leftContainer {
    width: 40%;
    background-color: teal;
    height: 100%;
}

.eMed_EP_ContentCenterAlign {
    display: flex;
    align-items: center;
    justify-content: center;
}

.eMed_on_click_nav {
    color: #FFFFFF;
    border-bottom: 2px solid white;
    width: 12.4vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .95vw;
    margin-top: 5vw;
}

.eMed_on_click_nav:hover {
    cursor: pointer;
}

.eMed_on_click_nav:active {
    color: rgba(0, 100, 100);
    border-color: rgba(0, 100, 100);
}


.eMed_EP_ContentRightAlign {
    margin-top: .5vw;
    display: flex;
    float: right;
}

.eMed_EP_rightContainer {
    display: flex;
    width: 60%;
    height: 100%;
    justify-content: center;
    overflow: "scroll"
}

.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
    color: teal !important;
}

.css-1e0gbd3-MuiInputBase-root-MuiInput-root-MuiSelect-root:before {
    border-bottom: 2px solid teal !important;
}

#eMed_Select_Current_Time {
    width: 35vw;
    box-shadow: 0px 3px 6px #00000040;
    opacity: 1;
    height: 2.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .5vw;
    font-size: 1vw;
}

.eMed_Scheduled_Time_Container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 36vw;
    height: 25vh;
    max-height: 30vh;
    overflow-x: scroll;
}

.eMed_Scheduled_Time {
    width: 6vw;
    background: #FFFFFF "0% 0 % no - repeat padding-box";
    box-shadow: 0px 3px 6px #00000040;
    opacity: 1;
    height: 2.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .5vw;
    color: black;
    margin: .5vw !important;
}

.eMed_Swipe_Container {
    display: flex;
    flex-direction: row;
    background-color: teal;
    border-radius: 2vw;
    height: 3.5vw;
    align-items: center;
    justify-content: center;
    width: 24vw;
}

#eMed_Swipe_Wrapper {
    background-color: white;
    width: 11.5vw;
    height: 2.5vw;
    border-radius: 1.2vw;
    font-size: 1.3vw;
    font-weight: 500;
    margin-right: .8vw;
}

.eMed_Swipe_text2 {
    color: white;
    margin-right: .5vw;
    font-size: 1.3vw;
}

::-webkit-scrollbar-thumb {
    background: rgb(255, 255, 255);
    border-radius: 100vw;
    visibility: visible;
    height: 1vw;
    width: 10vw;
}

.eMed_EP_Number_Wrapper {
    display: flex;
    flex-direction: row;
    margin-top: 3.5vw;
    position: relative;
}

.eMed_Dialpad_Message {
    color: white;
    font-size: .8vw;
    font-weight: 500;
}

.eMed_Number_Display{
    color: #FFFFFF;
    font-size: 1.85vw;
    font-weight: 400;
    letter-spacing: 1.5vw;
    height: 2.2vw;
    width: 26vw;
    display: flex;
    overflow: hidden;
}

.eMed_Number_Receiver_Container {
    display: flex;
    flex-direction: row;
}

.eMed_Number_Receiver {
    border-bottom: 1px solid white;
    width: 2vw;
    margin-left: .3vw;
    margin-right: .3vw;
}

.eMed_EP_Dialpad_Container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.eMed_EP_Dialpad_Wrapper {
    position: relative;
    cursor: pointer;
}

#eMed_EP_Dialpad_Img:active{
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(119%);
}

.eMed_EP_Dialpad_Text_Container {
    position: absolute;
    top: 25%;
    width: 100%;
    text-align: center;
    font-size: 1.5vw;
    color: white
}

.eMed_EP_Days_Text_Container{
    position: absolute;
    top: 17%;
    left: -8%;
    width: 100%;
    text-align: center;
    font-size: 3vw;
    font-weight: 600
}
.eMed_dilboxAnimations{
    width: 5vw;
    height: 10vh;
  }

  ::-webkit-scrollbar {
    width: 0.2vw;
    height: 0.2vw;
  }
  
  ::-webkit-scrollbar-thumb {
    background: teal;
    border-radius: 100vw;
    visibility: visible;
    height: 1vw;
    width: 10vw;
  }
  .eMed_DB_CameraBtn {
    display: flex;
    flex-direction: row
  }
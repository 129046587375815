@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }

} */

.total {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  flex-wrap: nowrap;
  height: 100vh;
}

.left {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  flex: 1 1;
  background-color:white;
}

.right {
  flex: 1 1;
  flex-wrap: nowrap;
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}
.login_input input{
  display: flex;
  border-radius: 5px;
   width:300px;
   background-color:#d9fbff;
   flex-direction: column;  
  
} 
.login_checkbox{
  display: flex;
 justify-content: space-between;
}

.btn{
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 7%;
}
.logo_img{
  height: 10%;
  display: flex;
  margin-top: 150px;
  background-color:white;

}
.total {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  flex-wrap: nowrap;
  height: 100vh;
}

.left {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  flex: 1 1;
  background-color: white;
}

.right {
  flex: 1 1;
  flex-wrap: nowrap;
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}
.login_input input {
  display: flex;
  border-radius: 5px;
  width: 300px;
  background-color: #d9fbff;
  flex-direction: column;
}
.eMed_login_checkbox {
  display: flex;
  justify-content: space-between;
  max-width: 90%;
  margin-top: 7%;
}

.butt {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 7%;
}
.logo_img {
  height: 10%;
  display: flex;
  margin-top: 150px;
  background-color: white;
}
.label_link {
  color: black;
  text-decoration: none;
}
.eMed_textFiled {
  max-width: 90%;
}
.eMed_passwordTextbox {
  margin-top: 7%;
}
.eMed_visibleIcon {
  cursor: pointer;
}
.eMed_dilboxAnimations{
  width: 5vw;
  height: 10vh;
}
.total {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  flex-wrap: nowrap;
  height: 100vh;
}

.left {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  flex: 1 1;
  background-color: white;
}

.right {
  flex: 1 1;
  flex-wrap: nowrap;
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}
.login_input input {
  display: flex;
  border-radius: 5px;
  width: 300px;
  background-color: white;
  flex-direction: column;
}
Button {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 7%;
  background-color: rgb(4, 183, 177);
  color: white;
  border: 1px solid white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.logo_img {
  height: 10%;
  display: flex;
  margin-top: 150px;
  background-color: white;
}
.link {
  color: white;
  text-decoration: none;
}

.eMed_dialogHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.eMed_cancel {
  cursor: pointer;
}

.eMed_dialogBody {
  display: flex;
  flex-direction: column;
}

.eMed_dialogbodyContent {
  display: flex;
  justify-content: center;
}

#eMed_dlgBtn {
  display: flex;
  justify-content: center;
}

#eMed_Dialog_title {
  font-size: 1.2vw;
}
.errorpage{
  text-align: center;
  padding: 70px;
  font-size: 20px;
   
}
.soonpage{
    font-size: 20px;
    text-align: center;
    padding: 70px;
}
* {
  font-size: 1vw;
}
.eMed_DB_container {
    display: flex;
    flex-direction: row;
    height: 100vh;
}
.eMed_DB_leftContainer {
    width: 40%;
    background-color: teal;
    height: 100%;
}
.eMed_DB_rightContainer {
    width: 60%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.eMed_DB_leftSubContainer {
    display: flex;
    flex-direction: column;
}
.eMed_DB_ContentCenterAlign {
    display: flex;
    align-items: center;
    justify-content: center;
}
.eMed_DB_txtcontentCenter {
    width: 100%;
    min-width: 75%;
    display: flex;
    text-align: center;
}

#eMed_DB_ButtonContainer{
    min-width: 16vw;
}
.eMed_DB_header{
    font-size: 2.5vw;
    color: #04b7b1;
}
.eMed_DB_hdr_Clinic {
    font-size:3.5vw;
    color: #04b7b1;
}
.logoutDiv{
    display: flex;
    /* flex-direction: column; */
    justify-content: flex-end;
    margin-right: 2vw;
}
.eMed_dilboxAnimations{
    width: 5vw;
    height: 10vh;
  }
.eMed_selectItem {
  width: 50vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5vw;
}
.eMed_settingsbtn {
  margin-top: 20px;
  width: 50vw;
  display: flex;
  justify-content: center;
}

/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

#eMed_TableHeaderText {
    font-size: 1.2vw;
}

.eMed_Main_Card {
    margin-top: 2vh;
}

.eMed_table_Container {
    height: 55vh;
}

#eMed_Icons_Avatar {
    width: 2.5vw;
    height: 2.5vw;
    box-shadow: 3;
}

#eMed_patient_Name_Column {
    width: 18vw;
    font-size: 1vw;
    cursor: pointer;
}


#eMed_PatientName {
    font-size: 1.1vw;
    min-width: 6vw;
}

#eMed_PatientAcc {
    font-size: 1vw;
}

#eMed_PatientMobile {
    font-size: 1vw;
}

#eMed_Age_Column {
    font-size: 1vw;
    min-width: 3vw;
}

#eMed_Tag_Column {
    max-width: 1vw;
}

#eMed_VisitPurpose_Column {
    min-width: 10vw;
    font-size: 1vw;
}

#eMed_Consultant_Column {
    max-width: 5vw;
    font-size: 1vw;
}

#eMed_Profile_Div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2vw 0 2vw;

}

#eMed_Profile_Avatar {
    width: 3.5vw;
    height: 3.5vw;
}

#eMed_Date_Column {
    width: 11vw;
    font-size: 1vw;
    cursor: pointer;
}

#eMed_Status_Column {
    width: 5px;
}

#eMed_Actions_Column_body {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#eMed_Actions_Column {
    min-width: 5vw;
}

.eMed_Sortable_Cells_Number {
    display: flex;
    flex-direction: row;
    justify-content: right;
}
.eMed_Sortable_Cells_String {
    display: flex;
    flex-direction: row;
    justify-content: left;
}
.eMed_Sortable_Cells_Date {
    display: flex;
    flex-direction: row;
    justify-content: right;
}

.MuiListItemText {
    font-size: 14px;
}

#eMed_Sorting_Icons {
    font-size: 1.2vw;
    margin-top: 2px;
}

#eMed_SearchIcon_div {
    margin-right: 3vw;
    display: flex;
    justify-content: end;
    margin-top: 0.7vw;
}

#eMed_Search {
    font-size: 2vw;
    margin-top: 2vh;
    margin-bottom: -1vh;
    margin-left: 1vw;
    cursor: pointer;
}
#eMed_Norecords_div{
    display: flex;
    justify-content: center;
    margin-top: 20vh;
    font-size: 1.5vw;
}
#eMed_Flex_cells{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#eMed_Sticky_HeadCell01{
    position: sticky;
    left: 0;
}
#eMed_Sticky_End{
    position: sticky;
    right: 0;
}
#eMed_Sticky_EndBody{
    position: sticky;
    right: 0;
    background-color: white;
}
#eMed_Sticky_HeadCell02{
    position: sticky;
    left: 47px;
}
#eMed_Non_Sticky_Head{
   z-index: 0;
}
#eMed_Sticky_Body01{
    position: sticky;
    left: 0;
    background-color: white;
}
#eMed_Sticky_Body02{
    position: sticky;
    left: 47px;
    background-color: white;
}

/* .MuiTablePagination-actions{
    color: aqua;
} */

/* .eMed_TableHeader_large {
    min-width: 13vw;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
#eMed_Table_HeadText {
    font-size: 1vw;
} */

* {
  font-size: 1vw;
}
.eMed_DB_container {
    display: flex;
    flex-direction: row;
    height: 100vh;
}
.eMed_DB_leftContainer {
    width: 40%;
    background-color: teal;
    height: 100%;
}
.eMed_DB_rightContainer {
    width: 60%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 10vh;
}
.eMed_DB_leftSubContainer {
    display: flex;
    flex-direction: column;
}
.eMed_DB_ContentCenterAlign {
    display: flex;
    align-items: center;
    justify-content: center;
}
.eMed_DB_txtcontentCenter {
    width: 40%;
}
.eMed_DB_lbl_valueContainer {
    display: flex;
    flex-direction: row;
}
.eMed_DB_lbl {
    min-width: 10vw;
    max-width: 10vw;
    margin: 10px;
}
.eMed_dilboxAnimations{
    width: 5vw;
    height: 10vh;
  }
  .eMed_DB_CameraBtn {
    display: flex;
    flex-direction: row
  }
  .eMed_DB_Error_txt{
    text-align: center;
    color: red;
    font-weight: 500;
    margin-top: 1vw;
    height: 1.5vw;
  }
.eMed_Payment_container {
    display: flex;
    flex-direction: row;
    height: 100vh;
}

.eMed_Payment_leftContainer {
    width: 40%;
    background-color: teal;
    height: 100%;
}

.eMed_Payment_ContentCenterAlign {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.eMed_on_patient_name {
    color: #FFFFFF;
    width: 25vw;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 1.3vw;
    margin-top: 1vw;
}

.eMed_Payment_QRHead {
    font-size: 2.5vw;
    font-weight: 500;
}

.eMed_Payment_amountTextdiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.eMed_Payment_amountText {
    font-size: 1vw;
}

.eMed_Payment_noteText {
    font-size: 1vw;
    width: 30vw;
    margin-top: 2vw;
    text-align: center;
}

.eMed_on_details {
    color: #FFFFFF;
    width: 20vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1vw;
    margin-top: 1vw;
    margin-left: 2vw;
}

.eMed_on_click_skip_pay {
    color: teal;
    border-bottom: 1px solid teal;
    width: 11.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1vw;
}

.eMed_on_click_skip_pay:hover {
    cursor: pointer;
}

.eMed_on_click_skip_pay:active {
    color: rgba(0, 100, 100);
    border-color: rgba(0, 100, 100);
}

.eMed_Payment_TokenMain {
    height: 10vw;
    width: 20vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: teal;
    border-radius: 0.4vw;
    margin-top: 1vw;
    box-shadow: 0px 4px 7px #00000040;
}

.eMed_Payment_TokenHead {
    font-size: 1.5vw;
    color: teal;
    font-weight: 500;
}

.eMed_Payment_TokenNum_Txt {
    font-size: 5vw;
    color: white;
    font-weight: 500;
}
.eMed_Payment_Date_Txt {
    font-size: 2.5vw;
    color: white;
    font-weight: 500;
}

.eMed_Payment_ContentRightAlign {
    margin-top: .5vw;
    display: flex;
    float: right;
}

.eMed_Payment_rightContainer {
    display: flex;
    width: 60%;
    height: 100%;
    justify-content: center;
    overflow: "scroll"
}

.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
    color: teal !important;
}

.css-1e0gbd3-MuiInputBase-root-MuiInput-root-MuiSelect-root:before {
    border-bottom: 2px solid teal !important;
}


::-webkit-scrollbar-thumb {
    background: rgb(255, 255, 255);
    border-radius: 100vw;
    visibility: visible;
    height: 1vw;
    width: 10vw;
}

.eMed_Payment_Home_Button{
    margin-top: 2vw;
}

.eMed_dilboxAnimations{
    width: 5vw;
    height: 10vh;
  }
.eMed_EP_container {
    display: flex;
    flex-direction: row;
    height: 100vh;
}

.eMed_EP_leftContainer {
    width: 40%;
    background-color: teal;
    height: 100%;
}

.eMed_EP_ContentCenterAlign {
    display: flex;
    align-items: center;
    justify-content: center;
}

.eMed_on_click_nav {
    color: #FFFFFF;
    border-bottom: 2px solid white;
    width: 12.4vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .95vw;
    margin-top: 5vw;
}

.eMed_on_click_nav:hover {
    cursor: pointer;
}

.eMed_on_click_nav:active {
    color: rgba(0, 100, 100);
    border-color: rgba(0, 100, 100);
}


.eMed_EP_ContentRightAlign {
    margin-top: .5vw;
    display: flex;
    float: right;
}

.eMed_EP_rightContainer {
    display: flex;
    width: 60%;
    height: 100%;
    justify-content: center;
    overflow: "scroll"
}

.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
    color: teal !important;
}

.css-1e0gbd3-MuiInputBase-root-MuiInput-root-MuiSelect-root:before {
    border-bottom: 2px solid teal !important;
}

#eMed_Select_Current_Time {
    width: 35vw;
    box-shadow: 0px 3px 6px #00000040;
    opacity: 1;
    height: 2.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .5vw;
    font-size: 1vw;
}

.eMed_Scheduled_Time_Container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 36vw;
    height: 25vh;
    max-height: 30vh;
    overflow-x: scroll;
}

.eMed_Scheduled_Time {
    width: 6vw;
    background: #FFFFFF "0% 0 % no - repeat padding-box";
    box-shadow: 0px 3px 6px #00000040;
    opacity: 1;
    height: 2.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .5vw;
    color: black;
    margin: .5vw !important;
}

.eMed_Swipe_Container {
    display: flex;
    flex-direction: row;
    background-color: teal;
    border-radius: 2vw;
    height: 3.5vw;
    align-items: center;
    justify-content: center;
    width: 24vw;
}

#eMed_Swipe_Wrapper {
    background-color: white;
    width: 11.5vw;
    height: 2.5vw;
    border-radius: 1.2vw;
    font-size: 1.3vw;
    font-weight: 500;
    margin-right: .8vw;
}

.eMed_Swipe_text2 {
    color: white;
    margin-right: .5vw;
    font-size: 1.3vw;
}

::-webkit-scrollbar-thumb {
    background: rgb(255, 255, 255);
    border-radius: 100vw;
    visibility: visible;
    height: 1vw;
    width: 10vw;
}

.eMed_EP_Number_Wrapper {
    display: flex;
    flex-direction: row;
    margin-top: 3.5vw;
    position: relative;
}

.eMed_Dialpad_Message {
    color: white;
    font-size: .8vw;
    font-weight: 500;
}

.eMed_Number_Display{
    color: #FFFFFF;
    font-size: 1.85vw;
    font-weight: 400;
    letter-spacing: 1.5vw;
    height: 2.2vw;
    width: 26vw;
    display: flex;
    overflow: hidden;
}

.eMed_Number_Receiver_Container {
    display: flex;
    flex-direction: row;
}

.eMed_Number_Receiver {
    border-bottom: 1px solid white;
    width: 2vw;
    margin-left: .3vw;
    margin-right: .3vw;
}

.eMed_EP_Dialpad_Container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.eMed_EP_Dialpad_Wrapper {
    position: relative;
    cursor: pointer;
}

#eMed_EP_Dialpad_Img:active{
    -webkit-filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(119%);
            filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(119%);
}

.eMed_EP_Dialpad_Text_Container {
    position: absolute;
    top: 25%;
    width: 100%;
    text-align: center;
    font-size: 1.5vw;
    color: white
}

.eMed_EP_Days_Text_Container{
    position: absolute;
    top: 17%;
    left: -8%;
    width: 100%;
    text-align: center;
    font-size: 3vw;
    font-weight: 600
}
.eMed_dilboxAnimations{
    width: 5vw;
    height: 10vh;
  }

  ::-webkit-scrollbar {
    width: 0.2vw;
    height: 0.2vw;
  }
  
  ::-webkit-scrollbar-thumb {
    background: teal;
    border-radius: 100vw;
    visibility: visible;
    height: 1vw;
    width: 10vw;
  }
  .eMed_DB_CameraBtn {
    display: flex;
    flex-direction: row
  }
.fieldHeaderfordoctor {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  max-width: 22%;
  border: 1px solid white;
  border-radius: 10px;
}

.eMed_user {
  width: 12vw;
  height: 2vw;
}
.eMed_loginuser {
  font-size: 0.9vw;
}
.eMed_LoginType {
  font-size: 0.8vw;
}
.eMed_servicetype {
  width: 8vw;
}

.eMed_headContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.eMed_golbalSearch {
  justify-content: center;
  align-items: center;
  margin-top: -1px;
  margin-left: 10px;
}

.emed_headerLogo {
  width: 5vw;
}

.doctorName {
  font-size: 0.8vw;
  font-weight: bold;
}

.eMed_profile {
  margin-left: 10px;
}

.Icon {
  color: white;
}

.opht {
  font-size: 0.7vw;
}

.eMed_searchbox {
  background-color: white;
  border-radius: 4px;
  max-width: 20vw;
}

.img_logo {
  width: 3vw;
}

.img_logo1 {
  height: 60%;
  width: 80%;
}

.label {
  font-size: 0.6vw;
  cursor: pointer;
}

.sublabel {
  font-size: 0.6vw;
}

.MenuDropdown {
  margin-left: 20px;
  font-size: 30px;
}

#emed_sidebarmenu {
  font-size: 12px;
}

.emed_Dialogboxwidth {
  padding: 15px;
  min-width: 600px;
  min-height: 400px;
}

.eMed_Drawer ::-webkit-scrollbar {
  display: none;
}

.eMed_AvatarMenu {
  width: 10vw;
  height: 13vh;
  float: right;
}

.eMed_searchbarIcon {
  cursor: pointer;
}

#eMed_menuHieghlight {
  margin: 10px;
  padding-left: 30px;
  border-radius: 4%;
}

.eMed_muiDrawer {
  display: flex;
  flex-grow: 1px;
  padding-top: 0vh;
}

#eMed_profileMenu {
  margin-top: 50px;
}

.eMed_menuIcon {
  margin-left: 8px;
}

.eMed_menuText {
  margin-left: -8px;
  font-size: 10px;
}

.eMed_subMenus {
  display: flex;
}

.eMed_muiModulepart {
  flex-grow: 1px;
  min-width: 94vw;
  padding-left: 10px;
  padding-right: 10px;
}

.eMed_menuItems {
  cursor: pointer;
  border-radius: 20%;
}

#eMed_side_list {
  margin-top: 0.5vw;
}

.eMed_appbar_menu {
  display: flex;
  width: 60vw;
  flex-direction: row;
  justify-content: flex-end;
}

#eMed_menu_icons {
  font-size: 2vw;
}
@media (min-width: 600px) {
  .css-hyum1k-MuiToolbar-root {
    min-height: 4vw !important;
  }
}
@media (min-width: 600px) {
  .css-13r55hi {
    min-height: 4vw !important;
  }
}
.css-13w9gz9{
  padding-left: 0!important;
  padding-right: 0!important;
}


.errorpage{
  text-align: center;
  padding: 70px;
  font-size: 20px;
   
}
.soonpage{
    font-size: 20px;
    text-align: center;
    padding: 70px;
}
.eMed_Payment_container {
    display: flex;
    flex-direction: row;
    height: 100vh;
}

.eMed_Payment_leftContainer {
    width: 40%;
    background-color: teal;
    height: 100%;
}

.eMed_Payment_ContentCenterAlign {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.eMed_on_patient_name {
    color: #FFFFFF;
    width: 25vw;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 1.3vw;
    margin-top: 1vw;
}

.eMed_Payment_QRHead {
    font-size: 2.5vw;
    font-weight: 500;
}

.eMed_Payment_amountTextdiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.eMed_Payment_amountText {
    font-size: 1vw;
}

.eMed_Payment_noteText {
    font-size: 1vw;
    width: 30vw;
    margin-top: 2vw;
    text-align: center;
}

.eMed_on_details {
    color: #FFFFFF;
    width: 20vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1vw;
    margin-top: 1vw;
    margin-left: 2vw;
}

.eMed_on_click_skip_pay {
    color: teal;
    border-bottom: 1px solid teal;
    width: 11.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1vw;
}

.eMed_on_click_skip_pay:hover {
    cursor: pointer;
}

.eMed_on_click_skip_pay:active {
    color: rgba(0, 100, 100);
    border-color: rgba(0, 100, 100);
}

.eMed_Payment_TokenMain {
    height: 10vw;
    width: 20vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: teal;
    border-radius: 0.4vw;
    margin-top: 1vw;
    box-shadow: 0px 4px 7px #00000040;
}

.eMed_Payment_TokenHead {
    font-size: 1.5vw;
    color: teal;
    font-weight: 500;
}

.eMed_Payment_TokenNum_Txt {
    font-size: 5vw;
    color: white;
    font-weight: 500;
}
.eMed_Payment_Date_Txt {
    font-size: 2.5vw;
    color: white;
    font-weight: 500;
}

.eMed_Payment_ContentRightAlign {
    margin-top: .5vw;
    display: flex;
    float: right;
}

.eMed_Payment_rightContainer {
    display: flex;
    width: 60%;
    height: 100%;
    justify-content: center;
    overflow: "scroll"
}

.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
    color: teal !important;
}

.css-1e0gbd3-MuiInputBase-root-MuiInput-root-MuiSelect-root:before {
    border-bottom: 2px solid teal !important;
}


::-webkit-scrollbar-thumb {
    background: rgb(255, 255, 255);
    border-radius: 100vw;
    visibility: visible;
    height: 1vw;
    width: 10vw;
}

.eMed_Payment_Home_Button{
    margin-top: 2vw;
}

.eMed_dilboxAnimations{
    width: 5vw;
    height: 10vh;
  }